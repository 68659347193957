<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow>
      <CCol>
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <CForm>
                <h1>Login</h1>
                <p class="text-muted">Sign In to your account</p>
                <CInput
                  v-model="username"
                  placeholder="Username"
                  autocomplete="username email"
                  @keyup.enter="Login()"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  v-model="password"
                  placeholder="Password"
                  type="password"
                  autocomplete="curent-password"
                  @keyup.enter="Login()"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CRow>
                  <CCol>
                    <CButton color="primary" class="px-4" @click="Login()">Login</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>

    <CModal
      title="Error"
      :show.sync="alertModal"
      color="primary"
    >
      {{ alertMessage }}
      <template #footer>
        <CButton @click="alertModal = false" color="secondary">Ok</CButton>
      </template>
    </CModal>
    <LoadingScreen v-show="isLoading" />
  </CContainer>
</template>

<script>

import store from '../store';
import axios from 'axios';
import LoadingScreen from '../components/LoadingScreen.vue'
var URL_Login = store.getters.URL + '/api/Authentication/Login';

export default {
  name: 'Login',
  components: {
    LoadingScreen,
  },
  data() {
    return { 
      username: '',
      password: '',
      alertModal: false, 
      alertMessage: '',
      isLoading: false
    }
  },
  methods: {
    Login() {
      this.isLoading = true;
      let modelLogin = {
        "username": this.username,
        "password": this.password
      };

      axios.post(URL_Login, modelLogin)
           .then(response => {
            //  //console.log(response.data);
             if (response.data.StatusCode === 200) {

               let result = JSON.parse(response.data.Result);
              //  //console.log('result', result);

               let token = {
                 token: ("Bearer " + result.Token), 
                 timestamp: new Date().getTime()
               }
               
              //  localStorage.setItem('IdeationToken', JSON.stringify(token));
              //  localStorage.setItem('userprofile', JSON.stringify(result));
              //  localStorage.setItem('username', this.username);
               localStorage.setItem('IdeationToken', JSON.stringify(token));
               localStorage.setItem('userprofile', JSON.stringify(result));
               localStorage.setItem('username', this.username);
               
              //  this.$router.push({ name: "Dashboard"});

              let CurrentPath = localStorage.getItem('CurrentPath');
              //console.log('CurrentPath', CurrentPath);

              if (CurrentPath === null || CurrentPath === "null" || CurrentPath === '') {
                window.location.href = '#/dashboard';
              } else {
                localStorage.removeItem('CurrentPath');
                window.location.href = CurrentPath;
              }
          
             } else {
               this.alertMessage = response.data.Message;
               this.alertModal = true;
             }
           })
           .catch(error => {
             this.alertMessage = response.data.Message;
             this.alertModal = true;
           });
    }
  },
  watch:{
    alertModal: function(newVal, oldVal){
      if(newVal == true)
      {
        this.isLoading = false;
      }
    }
  }
};

</script>
