<template>
  <div id="loadingScreen">
    <img
      class="img"
      src="../../src/assets/loading/Cube-1s-200px.gif"
      alt="loading..."
      width="60"
    />
  </div>
</template>

<script>
export default {
  name: "loadingScreen"
};
</script>

<style>
#loadingScreen {
  position: absolute;
  z-index: 1000;
  background-color: #ffffff8f;
  height: 100vh;
  width: 100vw;
  text-align: center;
  line-height: 100vh;
}
.img {
  position: relative;
  margin: auto;
  /* margin-top: 20%; */
}
</style>